import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    <div className='footerText'>
        <p>&copy; 2023 SyntaxSeeker. All rights reserved. Built by SyntaxSeeker.</p>
    </div>
  )
}
