import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Home } from "./aplication/home/pages/home/Home";
import { HomeAllPosts } from "./aplication/codeLC/components/home/home-posts/HomeAllPosts";
import { About } from "./aplication/about/pages/about/About";
import { Login } from "./aplication/auth/pages/auth/Login";
import { Signup } from "./aplication/auth/pages/auth/Singup";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoginStatus,
  getUser,
  selectIsLoggedIn,
  setTheme,
} from "./redux/features/auth/authSlice";
import { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ChangePassword } from "./aplication/auth/pages/changePassword/ChangePassword";
import { Leyout } from "./aplication/auth/components/layout/Leyaout";
import { Forgot } from "./aplication/auth/pages/auth/Forgot";
import { Reset } from "./aplication/auth/pages/auth/Reset";
import { LoginWithCode } from "./aplication/auth/pages/auth/LoginWithCode";
import { Verify } from "./aplication/auth/pages/auth/Verify";
import { Profile } from "./aplication/auth/pages/profile/Profile";
import { UserList } from "./aplication/auth/pages/userList/UserList";
import { NewProject } from "./aplication/codeLC/pages/newProject/NewProject";
import { ThePost } from "./aplication/codeLC/pages/thePost/ThePost";

import NewBlogPost from "./aplication/blog/pages/create-blog-post/NewBlogPost";
import { BlogPosts } from "./aplication/blog/pages/blog-posts/BlogPosts";
import { BlogPost } from "./aplication/blog/pages/blog-post/BlogPost";

import NewCourse from "./aplication/learn/pages/create-course-post/NewCourse";
import { JavascriptCoursePost } from "./aplication/learn/pages/javascript-course-post/JavascriptCoursePost";
import { GenericCourse } from "./aplication/learn/pages/courses/GenericCourse";

axios.defaults.withCredentials = true;

function App() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      document.body.className = savedTheme;
    }
    dispatch(getLoginStatus()).then(() => {
      if (isLoggedIn) {
        dispatch(getUser()).then((action) => {
          if (action.type.endsWith("fulfilled") && action.payload.theme) {
            if (action.payload.theme !== savedTheme) {
              localStorage.setItem('theme', action.payload.theme);
              document.body.className = action.payload.theme;
              dispatch(setTheme(action.payload.theme));
            }
          }
        });
      }
    });
  }, [dispatch, isLoggedIn]);
  
  return (
    <>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Routes>
            <Route path="/" element={
              <>
                <Helmet>
                  <title>SyntaxSeeker - Home</title>
                  <meta name="description" content="Welcome to SyntaxSeeker, your go-to platform for code snippets, programming blogs, and courses." />
                  <meta property="og:title" content="SyntaxSeeker - Home" />
                  <meta property="og:description" content="Welcome to SyntaxSeeker, your go-to platform for code snippets, programming blogs, and courses." />
                </Helmet>
                <Home />
              </>
            } />
            <Route path="/posts" element={
              <>
                <Helmet>
                  <title>SyntaxSeeker - Posts</title>
                  <meta name="description" content="Discover a variety of posts on different programming topics." />
                  <meta property="og:title" content="SyntaxSeeker - Posts" />
                  <meta property="og:description" content="Discover a variety of posts on different programming topics." />
                </Helmet>
                <HomeAllPosts />
              </>
            } />
            <Route path="/post/:id" element={<ThePost />} />

            <Route path="/blog" element={
              <>
                <Helmet>
                  <title>SyntaxSeeker - Blog</title>
                  <meta name="description" content="Read our latest blog posts on programming and development." />
                  <meta property="og:title" content="SyntaxSeeker - Blog" />
                  <meta property="og:description" content="Read our latest blog posts on programming and development." />
                </Helmet>
                <BlogPosts />
              </>
            } />
            <Route path="/blog/:id" element={<BlogPost />} />

            <Route path="/about" element={
              <>
                <Helmet>
                  <title>SyntaxSeeker - About</title>
                  <meta name="description" content="Learn more about SyntaxSeeker and our mission." />
                  <meta property="og:title" content="SyntaxSeeker - About" />
                  <meta property="og:description" content="Learn more about SyntaxSeeker and our mission." />
                </Helmet>
                <About />
              </>
            } />

            <Route path="/JavascriptCourse" element={
              <>
                <Helmet>
                  <title>SyntaxSeeker - JavaScript Course</title>
                  <meta name="description" content="Explore our JavaScript course to enhance your programming skills." />
                  <meta property="og:title" content="SyntaxSeeker - JavaScript Course" />
                  <meta property="og:description" content="Explore our JavaScript course to enhance your programming skills." />
                </Helmet>
                <GenericCourse courseName="javascript" title="JavaScript Course" />
              </>
            } />
            <Route path="/ReactCourse" element={
              <>
                <Helmet>
                  <title>SyntaxSeeker - React Course</title>
                  <meta name="description" content="Explore our React course to enhance your programming skills." />
                  <meta property="og:title" content="SyntaxSeeker - React Course" />
                  <meta property="og:description" content="Explore our React course to enhance your programming skills." />
                </Helmet>
                <GenericCourse courseName="react" title="React Course" />
              </>
            } />
            <Route path="/NodejsCourse" element={
              <>
                <Helmet>
                  <title>SyntaxSeeker - Node.js Course</title>
                  <meta name="description" content="Explore our Node.js course to enhance your programming skills." />
                  <meta property="og:title" content="SyntaxSeeker - Node.js Course" />
                  <meta property="og:description" content="Explore our Node.js course to enhance your programming skills." />
                </Helmet>
                <GenericCourse courseName="nodejs" title="Node.js Course" />
              </>
            } />
            <Route path="/Redux" element={
              <>
                <Helmet>
                  <title>SyntaxSeeker - Redux Course</title>
                  <meta name="description" content="Explore our Redux course to enhance your programming skills." />
                  <meta property="og:title" content="SyntaxSeeker - Redux Course" />
                  <meta property="og:description" content="Explore our Redux course to enhance your programming skills." />
                </Helmet>
                <GenericCourse courseName="redux" title="Redux Course" />
              </>
            } />

            <Route
              path="/javascriptCourse/:id"
              element={<JavascriptCoursePost />}
            />

            {isLoggedIn && (
              <>
                <Route path="/NewProject" element={<NewProject />} />
                <Route path="/NewBlogPost" element={<NewBlogPost />} />
                <Route path="/NewCourse" element={<NewCourse />} />
              </>
            )}
            <Route path="/login" element={<Login />} />
            <Route path="/singup" element={<Signup />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/resetPassword/:resetToken" element={<Reset />} />
            <Route path="/loginWithCode/:email" element={<LoginWithCode />} />
            <Route path="/verify/:verificationToken" element={<Verify />} />

            <Route
              path="/profile"
              element={
                <Leyout>
                  <Profile />
                </Leyout>
              }
            />
            <Route
              path="/changePassword"
              element={
                <Leyout>
                  <ChangePassword />
                </Leyout>
              }
            />
            <Route
              path="/users"
              element={
                <Leyout>
                  <UserList />
                </Leyout>
              }
            />
          </Routes>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
